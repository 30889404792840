.Section-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    background-color: #282828;
}

.File-selected {
    color: white;
}
