body {
    margin: 0;
    font-family:
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #282c34;
    min-height: 100vh;
}

code {
    font-family:
        source-code-pro,
        Menlo,
        Monaco,
        Consolas,
        "Courier New",
        monospace;
}

button {
    background-color: #282c34;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
}

button:hover {
    background-color: #fff;
    color: #282c34;
}

input {
    background-color: #282c34;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
}
