.Game {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: #282828;
    color: white;
}

.Game-content {
    margin-top: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}

.Game-content__score {
    font-size: 2em;
    margin-bottom: 0.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3vw;
}

.Game-content__score__right {
    color: green;
}

.Game-content__score__right__value {
    font-size: 1.5em;
}

.Game-content__score__wrong {
    color: red;
}

.Game-content__score__wrong__value {
    font-size: 1.5em;
}

.Game-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
}
